
$baseline: 4px;
.toastContainer {
	position: fixed;
	padding: $baseline * 4;
	width: 100%;
	bottom: 20px;
	right: 50px;
	pointer-events: none;
	z-index: 104;
}

.toast {
	transition: opacity 1s cubic-bezier(0, 0, 0.3, 1);
	font-size: 15px;
	font-weight: 500;
	width: 100%;
	margin-top: $baseline * 4;
	pointer-events: none;
	display: flex;
	justify-content: flex-end;
}

.toast__content {
	padding: $baseline * 4 $baseline * 3;
	background: rgba(0, 0, 0, 0.8);
	color: #fff;
	border-radius: 2px;
	display: inline;
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
	&.errorMessage {
		color: color;
		background: #ce1212;
	}
	&.successMessage {
		color: white;
		background: #1b5e20;
	}
}

.toast--dismissed {
	opacity: 0;
}