.navContainerFluid {
    height: 100vh;
    width: var(--nav-width);
    background: var(--white-color);
    display: inline-block;

    .navigationPositioning {
        position: absolute;
        height: calc(100vh - 100px);
        width: var(--nav-width);
        bottom: 0;
    }

    .container {
        position: relative;
        height: 100%;


        ul {
            margin: 0;
            list-style: none;
            padding: 0;
            display: inline-block;
            position: absolute;
            right: 0;

            li {
                margin: 15px 0;

                .navBtn {
                    display: flex;
                    align-items: center;
                    fill: var(--light-main-color);
                    color: var(--light-main-color);
                    border: none;
                    background: none;
                    cursor: pointer;
                    width: 187px;
                    height: 44px;
                    padding-left: 23px;
                    border-bottom-left-radius: 33px;
                    border-top-left-radius: 33px;
                    transition: .5s ease;
                    outline-color: var(--outline-color);


                    svg {
                        width: 26px;
                        height: 26px;
                    }

                    a {
                        font-size: 16px;
                        font-weight: 400;
                        margin-top: 3px;
                        letter-spacing: 0.5px;
                        text-decoration: none;
                    }

                    span {
                        width: 8px;
                    }


                }
                a{
                    text-decoration: none;
                }
                .navBtn:hover {
                    background: var(--secondary-color);
                    box-shadow: 0 0 19px -10px var(--secondary-color);
                    transition: .5s ease;
                    fill: var(--white-color);
                    color: var(--white-color);

                }
            }

        }


    }
}

.active {
    background: var(--secondary-color);
    box-shadow: 0 0 19px -12px var(--secondary-color);
    border-bottom-left-radius: 33px;
    border-top-left-radius: 33px;
    display: block;

    button{
        fill: var(--white-color) !important ;
        color: var(--white-color) !important;
    }

}

.logOutBtn{
    position: absolute;
    bottom: 53px;
    fill: var(--secondary-color);
    transition: .3s ease;
    left: 58px;

    button{
        border: none;
        background: none;
        transform: rotate(180deg);
        outline-color: var(--light-main-color);
        cursor: pointer;
        transition: .3s ease;

    }

    button:hover{
        fill: var(--hovered-color);
        transition: .3s ease;
    }
}

