.appHeader {
    position: absolute;
    top: 0;
    width: calc(100vw - var(--nav-width));
    right: 0;
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 1px 1px 6px #2021242b;
    background: var(--input-border-color);

    .rightSideHeader {
        display: flex;
        width: 100%;
        max-width: 281px;
        justify-content: space-evenly;
        align-items: center;

        span {
            svg {
                width: 26px;
                height: 26px;
                fill: var(--light-main-color);
            }
        }

        .userAvatarRow {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 126px;
            justify-content: space-around;

            .avatarImage {
                width: 35px;
                height: 35px;
                background: var(--white-color);
                border-radius: 50%;
                fill: var(--light-main-color);
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid var(--outline-color);

                svg {
                    width: 22px;
                    height: 22px;
                }
            }

            .username {
                font-weight: 500;
                color: var(--none-hard-text-color);
            }
        }
    }
}