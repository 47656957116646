.head {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  color: var(--main-black);
  fill: var(--non-hard-color);
  margin-left: 35px;
  align-items: center;

  button {
    border: none;
    background: none;
    cursor: pointer;
    margin-top: 3px;
    outline-color: var(--light-main-color);
    display: flex;
  }

  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    color: var(--non-hard-color);
    margin-top: 2px;
    margin-left: 7px;
  }
}
.orderDetailsContainerFluid {
  display: flex;
  justify-content: center;
  align-items: center;

  .orderDetailsContents {
    width: 100%;

    .orderRows {
      box-shadow: var(--small-shadow);
      margin: 10px 34px;
      padding: 2px 30px;
      padding-bottom: 26px;
      background-color: var(--white-color);
      border-radius: 6px;
      position: relative;

      .actionToogleContainer {
        position: absolute;
        right: 38px;
        display: flex;
        gap: 15px;
        top: 42px;

        .rejectBtn{
          background-color: #c51414 !important;
        }

        button {
          svg {
            background: transparent !important;
            width: 26px;
            height: auto;
            pointer-events: none;

            circle{
                stroke: var(--main-white);
                stroke-width: 5px;
            }
          }
        }

        .processBtn {
          text-transform: capitalize;
        }

        .deliveredBtn {
          text-transform: capitalize;
        }
      }

      h3 {
        font-size: 22px;
        font-weight: 400;
        margin-top: 38px;
        letter-spacing: 1px;
        color: #6d7073;
        position: relative;
      }

      h3::after {
        content: "";
        width: 54px;
        height: 2px;
        display: block;
        position: absolute;
        z-index: 1;
        background-color: var(--primary-blue-color);
        margin-top: 17px;
        opacity: 0.9;
      }

      ul {
        list-style: none;
        padding: 0;
        margin-top: 60px;

        .content {
          display: flex;
          color: #63676a;
          margin-top: 12px;
          font-size: 14px;
          font-weight: 100;

          .contentName {
            width: 136px;
          }

          .contentValue {
            margin-left: 28px;
          }
        }
      }

      .products {
        font-size: 19px;
        margin-bottom: 65px;
      }
      .slideCartItemRow {
        display: flex;
        justify-content: space-between;
        margin: 16px 0;
        max-width: 412px;
        background-color: var(--input-border-color);
        height: 121px;
        padding: 0 15px;
        border-radius: 5px;
        box-shadow: 0 7px 7px -5px #00000047;
        margin-top: 29px;
        cursor: pointer;

        .nameandtimes {
          display: flex;
          align-items: center;
        }

        .numberAndSign {
          width: 43px;
          display: flex;
          justify-content: space-around;
          height: 30px;
          padding: 4px;
          background: #9090902e;
          border-radius: 50px;
          align-items: center;
          font-size: 13px;
          margin-left: 12px;
          font-weight: 500;
        }
        .productImageDetails {
          display: flex;
          align-items: center;

          img {
            width: 100px;
            height: auto;
          }

          h4 {
            font-size: 17px;
            font-weight: 500;
            margin-left: 32px;
          }
        }

        .slideCartPrice {
          display: flex;
          align-items: center;

          h4 {
            font-weight: 600;
          }
        }
      }
    }
  }
}
