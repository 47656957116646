.buttonStyles{
    width: auto;
    height: 43px;
    border: none;
    background-color: var(--primary-blue-color);
    color: var(--main-white);
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    transition: .3s ease;
    outline-color: var(--hovered-color);
    cursor: pointer;
    padding: 0 17px;
}

.buttonStyles:hover{
    background-color: #0064ff;
    transition: .3s ease;
    box-shadow: 0px 3px 7px #00000057;
}

.buttonStyles:disabled{
    background-color: var(--ligth-white-color);
}

.buttonStyles:disabled:hover{
    background-color: var(--ligth-white-color);
    box-shadow: none;
    cursor: not-allowed;
}