.appContainer {
    width: calc(100vw - var(--nav-width));
    height: calc(100vh - var(--header-height));
    position: absolute;
    bottom: 0;
    right: 0;
    overflow-x: hidden;

    .apprelative {
        position: relative;
        width: 100%;
        height: 100%;
    }
}