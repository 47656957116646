.AdminSignInContainer{
    width: 100vw;
    height: 100vh;
    background-color: var(--ligth-white-color);
    display: flex;
    justify-content: center;

    .signInRow{
        width: 100%;
        height: 100%;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .row{
            max-width: 415px;
            max-height: 499px;
            height: 100%;
            background-color: var(--main-white);
            width: 100%;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 5px 27px -11px black;
            padding-bottom: 61px;

            input{
                border: 2px solid var(--input-border-color);
            }

            input:focus{
                border: 2.5px solid var(--primary-blue-color) !important;
                outline-color: var(--primary-blue-color) !important;
            }

            input:focus ~ label{
                color: var(--primary-blue-color) !important;
            }

            h3{
                font-weight: 600;
                font-size: 22px;
                text-align: center;
                margin-bottom: 52px;
                color: var(--primary-blue-color);
            }

            button{
                width: 100%;
                height: 45px;
                font-size: 15px;
                border: none;
                background-color: var(--primary-blue-color);
                font-weight: 700;
                color: var(--main-white);
                border-radius: 4px;
                margin-top: 19px;
                cursor: pointer;
                transition: .3s ease;
                outline-color: var(--gray-label);
            }

            button:disabled{
                background-color: var(--ligth-white-color);
                cursor: not-allowed;
            }

            button:hover:disabled {
                background-color: var(--ligth-white-color);
                box-shadow: none;
            }

            button:hover{
                background-color: #0064ff;
                box-shadow: 0 6px 12px -7px black;
                transition: .3s ease;
            }

            .inputField{
                margin-bottom: 27px;
            }
        }
    }
}

@media screen and (max-width: 600px){
    .AdminSignInContainer{
        background-color: var(--main-white) !important;
    }

    .AdminSignInContainer .signInRow .row{
        box-shadow: none;
    }
}