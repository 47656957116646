:root{
	--primary-color:  #f8f9fb;
	--accent-color: #313155;
	--white-color: #fff;
	--light-main-color: #9797a3;
	--outline-color: #9797a345;
	--secondary-color: #4162fb;
	--nav-width: 230px;
	--none-hard-text-color: #313155a1;
	--header-height: 45px;
	--none-solid-blue-hover: #517fac8c;
	--small-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
	--hovered-color: #6c9397;
	--main-white: #fff;
	--main-black : #444;
	--ligth-white-color: #909090c7;
	--input-border-color: #9090902b;
	--primary-blue-color: #2262c6;
	--gray-label: #796f64;
	--error-color: #f52c09;
	--weak-black-color: #6d7073;
	--non-hard-color: #71717b;
	--default-font-familly: Segoe UI, Roboto;
}

html, body {
	height: 100%;
	width: 100%;
	overflow: hidden;
	padding: 0;
	margin: 0;
	background: var(--primary-color);
	font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,ヒラギノ角ゴ Pro W3,Hiragino Kaku Gothic Pro,メイリオ,Meiryo,ＭＳ Ｐゴシック,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
	font-weight: 400;
	color: var(--accent-color);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
}

#app {
	height: 100%;
}
